// https://www.colourlovers.com/palette/1365151/piccadilly_sparrows
export default {
  PRIMARY: "#FB6900",
  SECONDARY: "",
  ORANGE: "#FB6900",
  RED: "#F63700",
  DARK_GREEN: "#004853",
  GREEN: "#007E80",
  LIGHT_GREEN: "#00B9BD",
  BG_DARK: "#004853",
  BG_LIGHT: "#e8e8e8",
  LIGHT_GRAY100: "#EDEDED",
  LIGHT_GRAY200: "#dbdbdb",
  TEXT_HIGHTLIGHTED: "#16ffbd",
  EVENTS: {
    TALK: {
      DESC: "TALK",
      PRIMARY: "rgb(251,105,0)",
      LIGHT: "rgba(251,105,0,0.37)"
    },
    FUN: {
      DESC: "FUN",
      PRIMARY: "rgb(0,185,189)",
      LIGHT: "rgba(0,185,189,0.37)"
    },
    PRAY: {
      DESC: "PRAY",
      PRIMARY: "rgb(246,55,0)",
      LIGHT: "rgba(246,55,0,0.37)"
    },
    OTHERS: {
      DESC: "DESC",
      PRIMARY: "rbg(189,186,186)",
      LIGHT: "rbga(189,186,186,0.36)"
    }
  }
};
