import styled from "styled-components";
import colors from "../../styles/colors";
import { Paragraph } from "../../styles/style";

export const Container = styled.div``;

export const MailParagraph = styled(Paragraph)`
  display: flex;
`;

export const Mail = styled.a`
  color: ${colors.LIGHT_GREEN};
  display: block;
`;

export const MailBlock = styled.div`
  display: inline-block;
  margin-left: 10px;
`;
