import styled from "styled-components";

export const Wrapper = styled.div`
  p {
    font-family: "Montserrat", sans-serif;
    margin-bottom: 20px;
    text-align: justify;
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;
